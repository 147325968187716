<!-- 真人视讯 -->
<template>
  <!-- <div class="page-live swipers">
    <div class="page-live-tab">
      <div class="swipers">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in newList" :key="index">
            <div class="live-tab">
              <div
                class="item"
                :class="{ 'item-act': curId === citem.id }"
                v-for="(citem, cindex) in item"
                :key="cindex"
                @click="switchTab(citem)"
              >
                {{ citem.name }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
    <div class="page-live-content">
      <div
        class="live-left"
        v-for="(item, index) in lists"
        v-show="curId == item.id"
        :key="'img' + index"
      >
        <img
          loading="lazy"
          v-lazy="item._imgUrl"
          class="animate__animated animate__bounceInLeft"
        />
      </div>
      <div class="live-right">
        <div class="text1">{{ curItem.name }}</div>
        <div class="text2">{{ curItem.nameEn }}</div>
        <div class="text3">{{ $t("gamePage.text28") }}</div>
        <div class="text4">{{ $t("gamePage.text29") }}</div>
        <div>{{ $t("gamePage.text30") }}</div>
        <div class="text3">{{ $t("gamePage.text31") }}</div>
        <div class="text4">{{ $t("gamePage.text32") }}</div>
        <div>{{ $t("gamePage.text33") }}</div>
        <div class="live-icon">
          <div
            class="icon-box"
            v-for="(item, index) in iconList"
            :key="'icon' + index"
          >
            <img :src="item.img" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="buttom" @click="_getToken(curItem, 2)">
          {{ $t("gamePage.text1") }}
        </div>
      </div>
    </div>
  </div> -->
  <div class="mod-virtual">
    <div class="mod-banner" style="height: 461.205px">
      <!--焦点图-->
      <Banner />
      <!--end 焦点图-->
    </div>
    <ul class="virtual-platform">
      <li
        class="cricket-item virtual-item"
        v-for="item in lists"
        :key="item.id"
      >
        <img loading="lazy" v-lazy="systemImgHost + item.imgUrl" />
        <a
          href="javascript:void(0);"
          onclick="$('.login-wrap').addClass('popup');"
        ></a>
      </li>
    </ul>

    <div class="sport-text page-text">
      <div class="layout" :class="{ 'layout-height': moreShow }">
        <h4>THE BEST DESTINATION FOR SPORTS BETTING ONLINE</h4>
        <p>
          It's time for your passion to pay back with online sports betting. And
          Fun88 is the best online sports betting site with more than 10,000
          games to play from. We make your entry into this arena of sports
          betting even more exciting with personalised bonuses and promotions!
        </p>
        <p>
          Whether you still play gully cricket on the streets or enjoy cheering
          in the packed cricket stadium, or reliving the action inside the
          22-yard cricket field in front of your television screen, now is the
          time to make the most of how you read the game. Casual cricket
          follower or an ardent fan, Fun88 offers you the best way to put your
          love for the game to the test with multiple betting options and a
          variety of bet types.
        </p>
        <p>
          With pre-match betting, live-match-betting and outcome betting, Fun88
          makes cricket gambling simpler, easier and more exciting than ever
          before with special bet type combinations and cricket betting tips and
          odds. From the pitch to the toss to the top match scorer to the
          winning side… Every nuance of the cricketing game is available for you
          to punt on. Easily accessible online on your mobile phone or laptop as
          you watch the match on the screen, Fun88 is your perfect cricket
          companion!
        </p>
        <p>
          The <a href="/in/sports/" target="_blank">betting on Fun88</a> covers
          international cricketing from across the world, encompassing all
          formats of the game – be it T20, test cricket and also premier
          leagues. With pre-match and in-play markets, competitive rates (odds),
          sports betting tips and analysis, Fun88 is packed with features that
          make it the best online betting destination. We bring our support on
          ground too as we partner with the Hero Caribbean Premier League 2020
          as their official betting partner.
        </p>
        <p>
          Along with cricket, there is live football sports action to gamble on
          as we cover everything from Premier leagues to International fixtures
          and youth tourneys. With football betting combinations available on
          live games, match results, goal scoring, events or accumulators, Fun88
          has lined up a thrilling experience for football lovers. With a
          comprehensive online sportsbook, competitive odds, on-the-go
          experience on our secure website and mobile app available for iOS and
          Android users, football fans will be hooked. Not to miss our fast
          settlement process and seamless payouts.
        </p>
        <p>
          Soccer fans will also love the support that Fun88 offers to their
          favourite clubs - Newcastle United Football Club, Tottenham Hotspur
          Football Club and others -with tickets, t-shirt giveaways and
          competitions! Official shirt sponsor and betting partner, Fun88 has
          enhanced these two sporting club brands and helped engage football
          fans in multiple ways.
        </p>
        <p>
          <a href="/in/" target="_blank">Fun88</a> also has kabaddi lined up
          amongst the sporting events one can bet on. One of India’s most
          popular contact sports, one can enjoy betting on Pro Kabaddi League
          and global kabaddi games. Enjoy this spectator sport with an edge, as
          you can bet on kabaddi match statistics, game-winners, players and
          more!
        </p>
        <p>
          Our entire focus on sports betting is backed by the best of
          technology, well-known partners and getting our fans all the analysis
          and competitive odds. All of this on the go with our mobile betting
          app and reliable transaction process.
        </p>
        <p>Fun88 the only leading online gaming site ever!</p>
      </div>
      <div @click="moreChange" class="read-more show">READ LESS</div>
    </div>
  </div>
</template>
<script>
import { _getToken } from "../../core/comp/common";
import { mapGetters } from "vuex";
import Banner from "../../core/components/Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      iconList: [
        {
          name: this.$t("gamePage.text34"),
          img: require("../../assets/images/game/black_lottery_a001_game1.png"),
        },
        {
          name: this.$t("gamePage.text35"),
          img: require("../../assets/images/game/black_lottery_a001_game2.png"),
        },
        {
          name: this.$t("gamePage.text36"),
          img: require("../../assets/images/game/black_lottery_a001_game3.png"),
        },
        {
          name: this.$t("gamePage.text37"),
          img: require("../../assets/images/game/black_lottery_a001_game4.png"),
        },
        {
          name: this.$t("gamePage.text38"),
          img: require("../../assets/images/game/black_lottery_a001_game5.png"),
        },
      ],
      curId: "",
      curItem: {},
      newList: [], // 分割之后的新数组
      swiperOption: {
        //swiper3
        // autoplay: 3000,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 30,
        // loop:true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        pagination: ".swiper-pagination",
        paginationType: "fraction",
      },
      moreShow: true,
    };
  },
  computed: {
    lists() {
      // 二级导航
      return this.gameMenu.arr_ty || [];
    },
    ...mapGetters([
      "gameMenu", // 游戏分类
      "systemImgHost",
    ]),
  },
  mounted() {
    this.splitList();
    this.curItem = this.lists[0];
    this.curId = this.lists[0].id;
  },
  methods: {
    _getToken,
    switchTab(item) {
      this.curItem = item;
      this.curId = item.id;
    },
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 5) {
        this.newList.push(this.lists.slice(i, i + 5));
      }
    },
    moreChange(){
       this.moreShow = !this.moreShow; 
    }
  },
};
</script>

<style lang="less" scoped>
.page-live {
  background: url("../../assets/images/game/black_lottery_a001_bg.jpg") center;
  background-size: 100% 100%;
}
.mod-virtual {
  min-height: 804px;
  overflow: hidden;
  .virtual-platform {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  ul {
    margin: 0 auto;
    list-style: none;
    padding: 0 0;
    li {
      width: 21%;
      height: 35.5vw;
      margin: 2% 0;
      padding: 0px 20px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      list-style-type: none;
      img {
        width: 100%;
        height: 100%;
      }
      a {
        background: url(../../assets/images/in/play_now_btn.png) no-repeat
          center center / contain;
        display: block;
        height: 3.8vw;
        width: 17vw;
        text-align: center;
        position: absolute;
        bottom: 6%;
        left: 15%;
      }
    }
  }
  .sport-text {
    margin-top: 30px;
    padding: 30px 0;
    background: #fff;
  }
  .page-text {
    max-width: 1400px;
    margin: 0 auto;
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
    }
    .layout-height{
        height: 300px;
    }
    h4 {
      font-size: 30px;
      line-height: 45px;
      text-align: center;
      font-weight: 600;
      color: #00a6ff;
      margin: 0 0;
      padding: 0 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;
      color: #404040;
      margin: 0 0;
      padding: 0 0 15px 0;
    }
    .read-more {
      width: 241px;
      height: 41px;
      margin: 30px auto 0;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #00a6ff;
      line-height: 41px;
      text-align: center;
      color: #00a6ff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
    a {
      color: #00a6ff;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
</style>